import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import RichContentBlockText from "../components/richContentBlockText"
import HighlightsBlock from "../components/highlightsBlock"
import { Caption, Heading, Body } from "../components/typography"
import { ItemsList, Container } from "../components/utils"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import { ExternalLinkButton } from "../components/button"
import { LanguageContext, languages } from "../context/langContext"
import { getStoryPath } from "../utils/path"

const StoryPage = ({ data: { story, site }, pageContext }) => {
  // console.log(story)
  const locale = pageContext.locale
  const providerLanguage = languages[locale]
  const i18nPaths = story._allSlugLocales.map(l => {
    return {
      locale: l.locale,
      value: getStoryPath(story, l.locale),
    }
  })

  return (
    <LanguageContext.Provider value={providerLanguage}>
      <Layout locale={locale} i18nPaths={i18nPaths}>
        <SEO title={story.companyName} lang={locale} />
        <Header locale={locale} theme="light" />
        <CustomContainer>
          <Intro>
            <div>
              <Caption color={DesignTokens.colors.primary[500]}>
                {story.label}
              </Caption>
              <Heading>{story.title}</Heading>
              {story.pdf && (
                <LanguageContext.Consumer>
                  {t => (
                    <ExternalLinkButton
                      target="blank"
                      rel="nofollow noopener"
                      href={story.pdf.url}
                    >
                      {t.downloadPdf}
                    </ExternalLinkButton>
                  )}
                </LanguageContext.Consumer>
              )}
              {story.link && (
                <ExternalLinkButton
                  href={story.link.url}
                  target={story.link.blank ? "blank" : null}
                  rel={story.link.blank ? "noopener nofollow" : null}
                >
                  {story.link.anchorText}
                </ExternalLinkButton>
              )}
            </div>
            {story.heroImage && (
              <img src={story.heroImage.url} alt={story.heroImage.alt} />
            )}
          </Intro>
          {story.highlights && (
            <HighlightsBlock html={story.highlights} title="Highlights" />
          )}
          <Content>
            <ItemsList>
              {story.companyDescription && (
                <li>
                  <LanguageContext.Consumer>
                    {t => <Body>{t.company}</Body>}
                  </LanguageContext.Consumer>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: story.companyDescription,
                    }}
                  />
                </li>
              )}
              {story.market && (
                <li>
                  <LanguageContext.Consumer>
                    {t => <Body>{t.market}</Body>}
                  </LanguageContext.Consumer>
                  <p>{story.market}</p>
                </li>
              )}
              {story.industry && (
                <li>
                  <p>Industry</p>
                  <p>{story.industry}</p>
                </li>
              )}
            </ItemsList>
            <RichContentBlockText html={story.text} />
          </Content>
        </CustomContainer>
      </Layout>
    </LanguageContext.Provider>
  )
}

const CustomContainer = styled(Container)`
  & > * {
    margin-bottom: 6rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 3rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Content = styled.section`
  padding-bottom: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > ul {
    max-width: 33%;
    @media screen and (max-width: 767px) {
      margin-top: 2rem;
      max-width: 100%;
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  & > div {
    width: 60%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  li > p:first-child {
    font-weight: 800;
    text-transform: uppercase;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`
const Intro = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin-top: ${DesignTokens.spacing[10]};
    & > * {
      margin-bottom: ${DesignTokens.spacing[8]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  img {
    border-radius: ${DesignTokens.borderRadius};
    margin-left: ${DesignTokens.spacing[10]};
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    div {
      margin-top: 0;
    }
    img {
      margin: 3rem 0;
    }
  }
`

export default StoryPage

export const query = graphql`
  query StoryQuery($slug: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    story: datoCmsStory(slug: { eq: $slug }, locale: { eq: $locale }) {
      title
      label
      text
      companyName
      companyDescription
      country
      market
      industry
      highlights
      heroImage {
        url
        alt
      }
      pdf {
        url
      }
      _allSlugLocales {
        value
        locale
      }
      link {
        url
        anchorText
        blank
      }
    }
  }
`
